<template>
    <label class="v-label theme--light combo-box" :required="$attrs.required" :for="fieldKey">
        <span class="label-wrap">{{ getLabel }}</span>
        <v-combobox
            :id="fieldKey"
            :key="fieldKey"
            v-model="value"
            v-bind="$attrs"
            :rules="form.rules[fieldKey]"
            :error-messages="form.errors[fieldKey]"
            outlined
            dense
            :hide-details="hideDetails"
            @input="clearErrors"
            v-on="$listeners"
        />
    </label>
</template>

<script>
import { humanizeStr } from '@/helpers/form/formatting'

export default {
    name: 'ComboBox',
    inheritAttrs: false,
    props: {
        form: {
            type: Object,
            default: () => ({
                data: {},
                rules: {},
                errors: [],
            }),
            required: true,
        },
        fieldKey: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        hideDetails: {
            type: [String, Boolean],
            default: 'auto',
        },
    },
    computed: {
        getLabel() {
            return this.label ? this.label : humanizeStr(this.fieldKey)
        },
        value: {
            get() {
                return this.form.data[this.fieldKey]
            },
            set(val) {
                this.form.data[this.fieldKey] = this.$attrs.type === 'number' ? parseInt(val) : val
            },
        },
    },
    methods: {
        clearErrors() {
            delete this.form.errors[this.fieldKey]
        },
    },
}
</script>
<style lang="scss" scoped>
.combo-box {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &::v-deep {
        .v-input {
            max-width: 100%;
            width: 400px;
        }

        .v-select__selections {
            padding: 0 !important;
        }
    }
    .label-wrap {
        width: 150px;
        line-height: 16px;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
}
</style>
