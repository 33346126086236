<template>
    <child-layout :title="(getRouteId ? 'Edit' : 'Create') + ' SIS Import Template'">
        <extended-form
            :submit="submit"
            :form-data="form.data"
            :form-ready="form.ready"
            :disabled="disabledForm"
            submit-text="Save"
        >
            <v-row>
                <v-col md="6">
                    <v-row>
                        <v-col><h4>Template Details</h4></v-col>
                        <v-col cols="12" class="py-0">
                            <text-field :form="form" field-key="title" />
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <text-field
                                :disabled="isEdit"
                                :form="form"
                                field-key="fileNameMask"
                                label="Import Template Name"
                                required
                                @change="validateComponentName"
                            />
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <auto-complete
                                :form="form"
                                :disabled="isEdit || fetchingComponents || !hasFileNameMask"
                                placeholder="Complete Import Template Name to populate this list"
                                item-text="componentName"
                                item-value="componentName"
                                field-key="componentName"
                                label="Import Type"
                                :items="sisComponents"
                                required
                                case-insensitive-match
                                @change="validateComponentName"
                            />
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <drop-down
                                v-model="form.data.schoolYear"
                                :form="form"
                                field-key="schoolYear"
                                label="School Year"
                                :items="yearItems"
                                required
                            />
                        </v-col>

                        <v-col sm="12" class="py-0">
                            <combo-box
                                :items="form.data.importTemplateFileNames"
                                :disabled="
                                    fetchingComponents || !hasFileNameMask || !hasComponentName
                                "
                                item-value="fileName"
                                item-text="fileName"
                                label="File Name Masks"
                                hint="Example: filename.csv or filename"
                                placeholder="Enter a filename and press enter to add"
                                :form="form"
                                field-key="importTemplateFileNames"
                                persistent-placeholder
                                deletable-chips
                                hide-selected
                                multiple
                                persistent-hint
                                small-chips
                                return-object
                                required
                                append-icon=""
                                :hide-details="false"
                                @change="updateFileNames"
                            />
                        </v-col>
                        <v-col cols="12">
                            <text-area :form="form" field-key="notes" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col md="6">
                    <v-row>
                        <v-col cols="12"><h4>Template Options</h4></v-col>
                        <v-col sm="6">
                            <v-switch
                                v-model="form.data.firstColumnIsName"
                                label="First Column is Name"
                                :rules="form.rules.firstColumnIsName"
                                inset
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col sm="6">
                            <v-switch
                                v-model="form.data.isActive"
                                label="Active"
                                :rules="form.rules.isActive"
                                inset
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col sm="6">
                            <v-switch
                                v-model="form.data.overrideThresholds"
                                label="Override Thresholds"
                                :rules="form.rules.overrideThresholds"
                                inset
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col sm="6">
                            <v-switch
                                v-model="form.data.isUpdateOnly"
                                label="Update Only"
                                :rules="form.rules.isUpdateOnly"
                                inset
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col sm="6">
                            <v-switch
                                v-model="form.data.isColumnBased"
                                label="Column Based"
                                :rules="form.rules.isColumnBased"
                                inset
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col sm="6">
                            <v-switch
                                v-model="form.data.isUpdateOnlyMatchOnSchool"
                                label="Update Only Match on School"
                                :rules="form.rules.isUpdateOnlyMatchOnSchool"
                                inset
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col>
                            <v-switch
                                v-model="form.data.inactivateRecordsNotInFile"
                                label="Inactive Records Not in File"
                                :rules="form.rules.inactivateRecordsNotInFile"
                                inset
                                hide-details="auto"
                            />
                        </v-col>
                        <v-col>
                            <v-switch
                                v-model="form.data.importFilesWithMissingFields"
                                label="Import Files with Missing Fields"
                                :rules="form.rules.importFilesWithMissingFields"
                                inset
                                hide-details="auto"
                            />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row> </v-row>

            <div v-if="isEdit">
                <h3>Column Mappings</h3>
                <v-divider class="pt-2" />
                <v-row>
                    <v-col md="4">
                        <v-file-input
                            v-model="uploadedColumnFile"
                            placeholder="Click here to upload column mapping file (CSV only)"
                            outlined
                            dense
                            @change="fileUpload"
                        />
                    </v-col>
                </v-row>
                <v-row v-show="importTemplateColumns.length > 0">
                    <v-col md="6">
                        <v-text-field
                            v-model="templateColumnTableFilter"
                            outlined
                            dense
                            prepend-icon="fal fa-search"
                            placeholder="Search Mapping Columns"
                        />
                    </v-col>
                </v-row>
                <v-row v-show="importTemplateColumns.length > 0">
                    <v-col sm="12">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>Header from Uploaded File</th>
                                        <th>Component Column Name</th>
                                        <th>Map Column Number</th>
                                        <th>Translations</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="column in filteredImportTemplateColumns"
                                        :key="column.id"
                                    >
                                        <td>
                                            <div class="d-flex flex-row align-baseline">
                                                <div
                                                    v-if="
                                                        column.existsInFile === false &&
                                                        uploadedColumnFile
                                                    "
                                                    class="pr-3"
                                                >
                                                    <v-tooltip right transition="transition">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                small
                                                                color="warning"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                >fas fa-info</v-icon
                                                            >
                                                        </template>
                                                        <span
                                                            >This mapping was missing from the most
                                                            recent uploaded file</span
                                                        >
                                                    </v-tooltip>
                                                </div>
                                                <div>
                                                    <div>
                                                        <span
                                                            v-if="isDuplicateColumnMapping(column)"
                                                        >
                                                            &#8224;
                                                        </span>
                                                        {{ column.mapColumnName }}
                                                    </div>
                                                    <div
                                                        v-if="column.valueFromFile"
                                                        class="caption font-italic"
                                                    >
                                                        {{
                                                            `(value from file: ${column.valueFromFile})`
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex">
                                                <v-autocomplete
                                                    v-model="
                                                        form.data.templateColumnComponentName[
                                                            column.id
                                                        ]
                                                    "
                                                    :rules="form.rules.templateColumnComponentName"
                                                    clearable
                                                    return-object
                                                    dense
                                                    hide-details="auto"
                                                    hide-selected
                                                    outlined
                                                    class="pa-2 a"
                                                    autocomplete="off"
                                                    :items="sortedDatabaseColumnMapOptions"
                                                    item-value="componentColumnName"
                                                    item-text="displayName"
                                                    required
                                                    @change="updateColumnMapping($event, column)"
                                                />
                                            </div>
                                        </td>
                                        <td>{{ column.mapColumnNumber }}</td>
                                        <td>
                                            <sis-import-translations-modal
                                                :column="column"
                                                :possible-values="
                                                    getPossibleValuesForColumn(column)
                                                "
                                                :translations="getTranslationsForColumn(column)"
                                                @hideDialog="hideDialog"
                                                @updateTranslations="updateTranslations"
                                            >
                                                <template v-slot:default="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        small
                                                        color="primary"
                                                        class="ma-2"
                                                        v-on="on"
                                                        >Translations
                                                    </v-btn>
                                                </template>
                                            </sis-import-translations-modal>
                                        </td>
                                        <td>
                                            <v-tooltip top transition="transition">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        icon
                                                        tabindex="-1"
                                                        v-on="on"
                                                        @click="deleteTemplateColumnRow(column)"
                                                    >
                                                        <v-icon>fas fa-trash</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Remove column mapping</span>
                                            </v-tooltip>
                                            <v-tooltip
                                                v-if="!isDuplicateColumnMapping(column)"
                                                top
                                                tabindex="-1"
                                                transition="transition"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        v-bind="attrs"
                                                        icon
                                                        v-on="on"
                                                        @click="addDuplicate(column)"
                                                    >
                                                        <v-icon>fas fa-copy</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add duplicate column mapping</span>
                                            </v-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <small><span>&#8224;</span> duplicate column mapping</small>
                    </v-col>
                </v-row>
            </div>
        </extended-form>
    </child-layout>
</template>

<script>
import {
    ROLE_SETUP_SIS_IMPORTS_TEMPLATES_CREATE,
    ROLE_SETUP_SIS_IMPORTS_TEMPLATES_EDIT,
} from '@/helpers/security/roles'
import { getYears, randomId } from '@/helpers/shared'
import { currentSchoolYear } from '@/helpers/form/formatting'
import ChildLayout from '@/components/layout/ChildLayout'
import ExtendedForm from '@/components/form/ExtendedForm'
import SisImportTranslationsModal from '@/views/DistrictManagement/SisImportTemplate/SisImportTranslationsModal'
import TextField from '@/components/form/TextField'
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar, formSnackbar } from '@/helpers/snackbar'
import DropDown from '@/components/form/DropDown'
import AutoComplete from '@/components/form/AutoComplete'
import TextArea from '@/components/form/TextArea'
import ComboBox from '@/components/form/ComboBox'
import { between } from '@/helpers/form/validation'

export default {
    components: {
        TextField,
        ExtendedForm,
        ChildLayout,
        SisImportTranslationsModal,
        DropDown,
        TextArea,
        ComboBox,
        AutoComplete,
    },
    data: () => ({
        selectedTranslation: {},
        templateColumnTableFilter: null,
        filteredMappedColumnNamesOptions: [],
        uploadedColumnFile: null,
        importTemplateColumns: [],
        importTemplateFileNames: [],
        searchDebounce: null,
        sisImport: null,
        fileNames: [],
        form: {
            data: {
                schoolYear: null,
                fileNameMask: null,
                componentName: null,
                title: null,
                firstColumnIsName: false,
                isActive: false,
                overrideThresholds: false,
                isColumnBased: false,
                inactivateRecordsNotInFile: false,
                isUpdateOnly: false,
                isUpdateOnlyMatchOnSchool: false,
                importFilesWithMissingFields: false,
                importTemplateFileNames: [],
                notes: null,
                templateColumnComponentName: {},
            },
            errors: {},
            rules: {
                schoolYear: [(v) => !!v || 'School Year is required'],
                fileNameMask: [
                    (v) => !!v || 'Import Template Name is required',
                    (v) => between(v, 0, 50),
                ],
                componentName: [(v) => !!v || 'Import Type is required', (v) => between(v, 0, 50)],
                importTemplateFileNames: [
                    (v) => {
                        const errMsg = 'Filenames must be between 1 and 20 characters'
                        const errs = v.reduce((acc, curr) => {
                            const err = between(curr.fileName, 1, 255, errMsg)
                            if (err) {
                                acc.push(err)
                            }

                            return acc
                        }, [])

                        return errs[0] || true
                    },
                ],
                firstColumnIsName: [],
                isActive: [],
                overrideThresholds: [],
                isColumnBased: [],
                title: [(v) => between(v, 0, 100)],
                inactivateRecordsNotInFile: [],
                isUpdateOnly: [],
                isUpdateOnlyMatchOnSchool: [],
                importFilesWithMissingFields: [],
                notes: [],
                templateColumnComponentName: [(v) => !!v || 'Column Component Name is required'],
            },
            ready: false,
        },
    }),
    computed: {
        ...mapGetters({
            sisImportErrors: 'sisImports/getErrors',
            sisImportFetching: 'sisImports/getFetching',
            sisImportSaving: 'sisImports/getSaving',
            getSisImportById: 'sisImports/getItemById',
            sortedDatabaseColumnMap: 'databaseColumnMap/getSortedItems',
            databaseColumnMapItems: 'databaseColumnMap/getItems',
            getDatabaseColumnMap: 'databaseColumnMap/getColumnMapByField',
            fetchingDatabaseColumnMap: 'databaseColumnMap/getFetching',
            getDistrictId: 'getDistrictId',
            getDistrictLea: 'getDistrictLea',
            importTemplateTranslations: 'sisImportTranslations/getItems',
            importPossibleValues: 'sisImportPossibleValues/getItems',
            hasRole: 'auth/hasRole',
            fetchingComponents: 'components/getFetching',
            sisComponents: 'components/getItems',
            possibleValues: 'possibleValues/getItems',
        }),
        disabledForm() {
            return (
                !this.hasAccessRole ||
                this.sisImportFetching ||
                this.fetchingComponents ||
                this.sisImportSaving ||
                !this.form.ready
            )
        },
        getRouteId() {
            return this.$route.params.id
        },
        isEdit() {
            return this.$route.name === 'sis-imports-edit'
        },
        yearItems() {
            return getYears(currentSchoolYear(), 12)
        },
        hasFileNameMask() {
            return this.form.data?.fileNameMask?.length > 0
        },
        hasComponentName() {
            return this.form.data?.componentName?.length > 0
        },
        sortedDatabaseColumnMapOptions() {
            const selectedItems = Object.values(this.form.data.templateColumnComponentName).reduce(
                (acc, curr) => {
                    if (!curr) {
                        return acc
                    }
                    acc.push(curr.componentColumnName)

                    return acc
                },
                []
            )

            let dividerLocation = null

            const items = this.sortedDatabaseColumnMap.map((item, idx) => {
                if (item.isRequired) {
                    //add an asterisks to required fields
                    item.displayName = item.componentColumnName + ' *'
                }

                if (!item.isRequired && dividerLocation === null) {
                    dividerLocation = idx
                }
                item.disabled = !!selectedItems.includes(item.componentColumnName)

                return item
            })

            //Add divider after required items
            if (items.length > 0 && dividerLocation !== null) {
                items.splice(dividerLocation, 0, { divider: true })
            }

            return items
        },
        hasAccessRole() {
            const validRole = this.isEdit
                ? ROLE_SETUP_SIS_IMPORTS_TEMPLATES_EDIT
                : ROLE_SETUP_SIS_IMPORTS_TEMPLATES_CREATE

            return this.hasRole(validRole)
        },
        redirectRoute() {
            return this.$router.resolve({
                params: { districtId: this.getDistrictId },
                name: 'sis-imports',
            })
        },
        filteredImportTemplateColumns() {
            const filterString = this.templateColumnTableFilter
                ? this.templateColumnTableFilter.toLowerCase()
                : null

            //Sort from a copy of the templates so we dont infinite loop the watcher
            const importTemplateColumns = [...this.importTemplateColumns].sort((a, b) =>
                //sort by mapColumnNumber ASC
                a.mapColumnNumber > b.mapColumnNumber ? 1 : -1
            )

            return importTemplateColumns.filter((item) => {
                if (item.toBeDeleted) {
                    return false
                }

                if (!filterString) {
                    return true
                }

                return (
                    item.componentColumnName.toLowerCase().includes(filterString) ||
                    item.mapColumnName.toLowerCase().includes(filterString)
                )
            })
        },
    },
    async mounted() {
        const formData = {}

        if (this.isEdit) {
            await Promise.all([
                this.fetchComponents(),
                //fetch template by id and lea. If this doesn't work throw an error
                //that the template doesn't exist.
                this.getNested({
                    lea: this.getDistrictLea,
                    id: this.getRouteId,
                }),
            ])

            this.sisImport = this.getSisImportById(this.getRouteId)

            if (!this.sisImport) {
                const subtext = `Could not find template by id (${this.getRouteId})`
                errorSnackbar({ subtext })
                await this.$router.push(this.redirectRoute.href)

                return
            }

            const { componentName, importTemplateColumns, importTemplateFileNames } = this.sisImport

            await Promise.all([
                this.fetchPossibleValues({ componentName }),
                this.fetchDatabaseColumnMap({ componentName }),
            ])

            //Map data from the importTemplate to the form data (only copying the form data items)
            this.form.data = Object.keys(this.form.data).reduce((acc, key) => {
                acc[key] = this.sisImport[key] || this.form.data[key]

                return acc
            }, {})

            this.importTemplateColumns = importTemplateColumns.reduce((item, curr) => {
                const databaseColumnMap = this.getDatabaseColumnMap(
                    'componentColumnName',
                    curr.componentColumnName
                )
                curr.isRequired = databaseColumnMap?.isRequired
                curr.displayName = curr.componentColumnName + (curr.isRequired ? ' *' : '')
                curr.toBeAdded = false
                curr.toBeDeleted = false
                curr.toBeUpdated = false

                item.push(curr)

                return item
            }, [])

            this.importTemplateFileNames = importTemplateFileNames
            this.form.data.templateColumnComponentName = this.importTemplateColumns.reduce(
                (acc, curr) => {
                    acc[curr.id] = curr
                    return acc
                },
                {}
            )
        } else {
            await this.fetchComponents()
            this.form.data.schoolYear = currentSchoolYear()
        }

        this.form.ready = true
    },
    methods: {
        ...mapActions({
            postTemplates: 'sisImports/post',
            patchTemplates: 'sisImports/patch',
            getTemplates: 'sisImports/get',
            getNested: 'sisImports/getNested',
            fetchComponents: 'components/get',
            fetchPossibleValues: 'possibleValues/get',
            fetchDatabaseColumnMap: 'databaseColumnMap/get',
        }),
        /*
         * fileNames contains the current filenames in the input field. so when one is deleted
         * we wont know unless we have an original copy (importTemplateFileNames). We need to
         * diff fileNames with importTemplateFileNames to find the ones that have been delete.
         */
        updateFileNames(fileNames) {
            const { componentName, fileNameMask } = this.form.data

            const newFileNames = fileNames.reduce((acc, curr) => {
                if (typeof curr === 'string') {
                    acc.push({
                        fileName: curr,
                        componentName,
                        fileNameMask,
                        importTemplateId: this.sisImport?.id,
                        lea: this.getDistrictLea,
                        toBeAdded: true,
                    })

                    return acc
                }

                return acc
            }, [])

            const existingFileNames = this.importTemplateFileNames.reduce((acc, curr) => {
                const idx = fileNames.findIndex((f) => {
                    if (typeof f === 'string') {
                        return true
                    }
                    return f.fileName === curr.fileName
                })
                if (idx === -1) {
                    delete curr.toBeAdded
                    curr.toBeDeleted = true
                }
                acc.push(curr)

                return acc
            }, [])

            this.importTemplateFileNames = [...newFileNames, ...existingFileNames]
            this.form.data.importTemplateFileNames = [
                ...newFileNames,
                ...existingFileNames.filter((e) => e.toBeDeleted !== true),
            ]
        },
        async validateComponentName(e) {
            const { componentName, fileNameMask } = this.form.data
            if (!componentName || !fileNameMask) {
                return
            }

            await this.getTemplates({ componentName, lea: this.getDistrictLea })
            const componentExists = this.getSisImportById(fileNameMask, 'fileNameMask')

            let errorMsg = undefined
            if (componentExists) {
                errorMsg = 'A template with this name already exists'
            }

            //Need to add an object. If we just add the fileNameMask then Vue's observer doesn't see it
            this.form.errors = {
                ...this.form.errors,
                fileNameMask: errorMsg,
            }
        },
        /*
         * possibleValues is the full list of available translations.
         * importPossibleValues is the list of applied translations.
         * We need to merge these two, using the items from importPossibleValues if they exist
         */
        getPossibleValuesForColumn(column) {
            const pv = this.possibleValues.reduce((acc, curr) => {
                if (column.componentColumnName !== curr.componentColumnName) {
                    return acc
                }
                acc[`${curr.componentColumnName}-${curr.possibleValue}`] = curr

                return acc
            }, {})

            const existingPv = this.importPossibleValues.reduce((acc, curr) => {
                if (column.componentColumnName !== curr.componentColumnName) {
                    return acc
                }
                acc[`${curr.componentColumnName}-${curr.possibleValue}`] = curr

                return acc
            }, {})

            return Object.values({ ...pv, ...existingPv })
        },
        getTranslationsForColumn(column) {
            return this.importTemplateTranslations.filter(
                (item) => item.componentColumnName === column.componentColumnName
            )
        },
        showDialog(column) {
            this.selectedTranslation = column
        },
        hideDialog() {
            this.selectedTranslation = {}
        },
        //Take in the translations from the translation model. Compare those with the existing translations and
        //mark them as new or deleted.
        updateTranslations({ templateColumn, translations }) {
            const existingTranslations = []
            const addTranslations = []
            Object.keys(translations).forEach((key) => {
                //New Translations are just strings in the translations[key] array
                const newTranslations = translations[key].filter(
                    (translation) => typeof translation === 'string'
                )

                //Existing translations are NOT strings, they are objects.
                existingTranslations.push(
                    translations[key].filter((translation) => typeof translation !== 'string')
                )

                newTranslations.forEach((translateFrom) => {
                    const newTranslation = this.createNewTemplateTranslation({
                        lea: templateColumn.lea,
                        fileNameMask: templateColumn.fileNameMask,
                        componentName: templateColumn.componentName,
                        componentColumnName: templateColumn.componentColumnName,
                        importTemplateId: templateColumn.importTemplateId,
                        translateFrom: translateFrom,
                        translateTo: key,
                    })

                    addTranslations.push(newTranslation)
                })
            })
            const translationsForColumn = this.getTranslationsForColumn(templateColumn)
            /*
             * Check if the translations exist in the existing translations returned from the updateTranslations event.
             * If they do not exist then mark them 'toBeDeleted'
             */
            translationsForColumn.forEach((translation) => {
                const exists = existingTranslations
                    .flat()
                    .find((existingTranslation) => existingTranslation.id === translation.id)
                if (!exists) {
                    const deleteTemplate = this.importTemplateTranslations.find(
                        (item) => item.id === translation.id
                    )
                    deleteTemplate.toBeDeleted = true
                }
            })

            this.importTemplateTranslations.push(...addTranslations)
        },
        async fileUpload(file) {
            if (!file) {
                return
            }
            const allowedFileTypes = [
                //Standard csv extension - https://www.rfc-editor.org/rfc/rfc7111
                'text/csv',
                //Microsofts magical csv extension
                'application/vnd.ms-excel',
                //other non-standard extensions that might show up
                'text/plain',
                'application/csv',
                'text/x-csv',
                'text/comma-separated-values',
                'text/x-comma-separated-values',
            ]

            if (!allowedFileTypes.includes(file.type)) {
                this.uploadedColumnFile = null
                errorSnackbar({
                    message: 'Error uploading file',
                    subtext: `Invalid file type. Allowed types: "${allowedFileTypes.join(
                        ', '
                    )}" Type provided: "${file.type}"`,
                })
                return
            }

            const fileContent = await file.text()
            const contentRows = fileContent.split('\n')
            const headers = contentRows[0]
            if (!headers) {
                errorSnackbar({
                    message: 'Error uploading file',
                    subtext: 'File is missing headers',
                })
                return
            }

            const firstRowData = contentRows[1] ? contentRows[1].split(',') : []
            let headerValues = headers.split(',')
            const { id, componentName, fileNameMask } = this.sisImport

            //remove special characters from columns. They will break imports
            headerValues = headerValues.map((h) => h.replace(/[^a-zA-Z0-9 ]/g, ''))

            //mark the the missing columns as existsInFile = false so we can warn the user
            const missingColumns = this.importTemplateColumns.reduce((acc, templateColumn, key) => {
                const idx = headerValues.findIndex((h) => h === templateColumn.mapColumnName)
                if (idx === -1) {
                    acc[key] = templateColumn
                }

                return acc
            }, {})

            Object.entries(missingColumns).forEach(([key, value]) => {
                value.existsInFile = false
                this.$set(this.importTemplateColumns, key, value)
            })

            headerValues.forEach((columnValue, key) => {
                const columnExists = this.importTemplateColumns.findIndex(
                    (item) => item.mapColumnName === columnValue
                )

                //if the column already exists in the table, skip it
                if (columnExists !== -1) {
                    return
                }

                const newTemplateColumn = this.createNewTemplateColumn({
                    mapColumnName: columnValue,
                    valueFromFile: firstRowData[key] || null,
                    id: randomId('add_template_column_'),
                    mapColumnNumber: key,
                    fileNameMask: fileNameMask,
                    componentName: componentName,
                    importTemplateId: id,
                    existsInFile: true,
                    componentColumnName: undefined,
                })

                this.$set(
                    this.importTemplateColumns,
                    this.importTemplateColumns.length,
                    newTemplateColumn
                )
            })
        },
        /**
         * @param selectedMappingColumn {Object} | undefined
         * @param templateColumn {Object}
         *
         * selectedMappingColumn is undefined when the user clears out the Component Column Name
         * Copy the values from the selectedMappingColumn into the templateColumn. templateColumn is what will
         * be sent to the backend and saved
         */
        updateColumnMapping(selectedMappingColumn, templateColumn) {
            const columnId = templateColumn.id
            templateColumn.componentColumnName = selectedMappingColumn?.componentColumnName || ''
            templateColumn.displayName = selectedMappingColumn?.displayName
            templateColumn.toBeUpdated = Number.isInteger(columnId) //mark the existing templates to be updated

            if (selectedMappingColumn) {
                this.$set(
                    this.form.data.templateColumnComponentName,
                    columnId,
                    selectedMappingColumn
                )
            } else {
                this.$delete(this.form.data.templateColumnComponentName, columnId)
            }

            const colIdx = this.importTemplateColumns.findIndex((c) => c.id === columnId)
            if (colIdx === -1) {
                return
            }

            this.$set(this.importTemplateColumns, colIdx, templateColumn)
        },
        /**
         * @param templateColumn
         * Mark the templateColumn to be deleted. Update the state to reflect that.
         */
        deleteTemplateColumnRow(templateColumn) {
            this.$delete(this.form.data.templateColumnComponentName, templateColumn.id)
            const colIdx = this.importTemplateColumns.findIndex((item) => {
                return templateColumn.id === item.id
            })
            if (colIdx === -1) {
                return
            }

            templateColumn.toBeDeleted = true
            delete templateColumn.toBeAdded
            this.$set(this.importTemplateColumns, colIdx, templateColumn)
        },
        addDuplicate(column) {
            const duplicateColumn = { ...column }
            let colIdx = this.importTemplateColumns.findIndex(
                (item) => item.mapColumnName === column.mapColumnName
            )

            if (colIdx === -1) {
                return
            }

            const newTemplateColumn = this.createNewTemplateColumn({
                ...duplicateColumn,
                componentColumnName: '',
                id: randomId('duplicate_template_column_'),
            })

            this.$set(
                this.importTemplateColumns,
                this.importTemplateColumns.length,
                newTemplateColumn
            )
        },
        createNewTemplateColumn: (values) => ({
            ...values,
            componentColumnName: '',
            isRequired: 'false',
            toBeAdded: true,
            toBeDeleted: false,
            toBeUpdated: false,
        }),
        createNewTemplateTranslation: (values) => ({ ...values, toBeAdded: true }),
        isDuplicateColumnMapping: ({ id }) =>
            Number.isInteger(id) ? false : id.includes('duplicate'),
        async submit() {
            // Check the translations to see if a matching templateColumn
            // was cleared out or flat out deleted. If so, set that translations for delete
            const shouldBeDeleted = this.importTemplateTranslations.reduce((acc, curr, index) => {
                //if importTemplateColumns does not have a record using componentColumnName then
                // we can assume that records translations should be deleted
                const exists = this.importTemplateColumns.find(
                    (c) => c.componentColumnName === curr.componentColumnName
                )

                if (!exists) {
                    acc.push(index)
                }

                return acc
            }, [])

            shouldBeDeleted.forEach((index) => {
                const translation = this.importTemplateTranslations[index]
                translation.toBeDeleted = true
                this.$set(this.importTemplateTranslations, index, translation)
            })

            const addColumns = this.importTemplateColumns.filter((c) => c.toBeAdded)
            const deleteColumns = this.importTemplateColumns.filter(
                (c) => c.toBeDeleted && Number.isInteger(c.id)
            )
            const updateColumns = this.importTemplateColumns.filter((c) => c.toBeUpdated)
            const addTranslations = this.importTemplateTranslations.filter((t) => t.toBeAdded)
            const deleteTranslations = this.importTemplateTranslations.filter(
                (t) => t.toBeDeleted && Number.isInteger(t.id)
            )
            const addFileNames = this.importTemplateFileNames.filter((t) => t.toBeAdded)
            const deleteFileNames = this.importTemplateFileNames.filter((t) => t.toBeDeleted)

            const payload = {
                lea: this.getDistrictLea,
                data: {
                    importTemplate: { ...this.form.data },
                    importTemplateColumns: {
                        add: addColumns,
                        delete: deleteColumns,
                        update: updateColumns,
                    },
                    importTemplateTranslations: {
                        add: addTranslations,
                        delete: deleteTranslations,
                    },
                    importTemplateFileNames: {
                        add: addFileNames,
                        delete: deleteFileNames,
                    },
                },
            }

            //save data
            if (this.isEdit) {
                await this.patchTemplates({ id: this.sisImport.id, ...payload })
            } else {
                await this.postTemplates(payload)
            }

            if (this.sisImportErrors.length) {
                const errors = this.sisImportErrors[0] || null

                this.form.errors = errors ? errors.details : []
            } else {
                const { componentName } = this.form.data
                await formSnackbar({
                    isEdit: this.isEdit,
                    type: 'Sis Template',
                    identifier: componentName,
                })

                await this.$router.push(this.redirectRoute.href)
            }
        },
    },
}
</script>
