<template>
    <base-dialog
        v-if="possibleValues.length > 0"
        v-model="opened"
        :max-width="maxWidth"
        :title="title"
        :confirm-action="updateTranslations"
        confirm-text="Confirm"
    >
        <template v-slot:activator="{ on, attrs }">
            <slot :on="on" :attrs="attrs"></slot>
        </template>

        <template v-slot:content>
            <v-simple-table>
                <thead>
                    <tr>
                        <th>To</th>
                        <th>From</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(possibleValue, key) in possibleValues" :key="key">
                        <td>
                            {{ possibleValue.possibleValue }}
                        </td>
                        <td>
                            <v-combobox
                                v-model="
                                    importTemplateTranslationModel[possibleValue.possibleValue]
                                "
                                :items="getTranslationsForPossibleValues(possibleValue)"
                                item-text="translateFrom"
                                item-value="id"
                                deletable-chips
                                hide-details
                                dense
                                chips
                                multiple
                                return-object
                            />
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </template>
    </base-dialog>
</template>

<script>
import BaseDialog from '@/components/modal/BaseDialog'

export default {
    components: { BaseDialog },
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
        possibleValues: {
            type: Array,
            default: () => [],
        },
        translations: {
            type: Array,
            default: () => [],
        },
    },
    data: function () {
        return {
            opened: false,
            maxWidth: '40%',
            title: `Translations for ${this.column.mapColumnName}`,
            importTemplateTranslationModel: {},
        }
    },
    async mounted() {
        this.importTemplateTranslationModel = await this.possibleValues.reduce((acc, curr) => {
            acc[curr.possibleValue] = this.getTranslationsForPossibleValues(curr)
            return acc
        }, {})
    },
    methods: {
        hideDialog() {
            this.$emit('hideDialog', this.column)
            this.opened = false
        },
        updateTranslations() {
            this.$emit('updateTranslations', {
                templateColumn: this.column,
                translations: this.importTemplateTranslationModel,
            })

            this.hideDialog()
        },
        getTranslationsForPossibleValues(possibleValue) {
            return this.translations.filter(
                (translation) => translation.translateTo === possibleValue.possibleValue
            )
        },
    },
}
</script>
